import {
    AboutLink,
    CategoryTitles,
    ColumnContainer,
    ColumnTitle,
    ColumnTitles,
    MenuButton,
    MenuClosed,
    MenuOpen,
    Nav
} from './Navigation.style'
import { Box, Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import { Category } from '~/types'
import CategoryFilter from '~/components/CategoryFilter'
import ColorTypography from '~/components/ColorTypography'
import Desktop from '~/components/Desktop'
import LineBreak from '~/components/LineBreak'
import { Link } from 'gatsby'
import Mobile from '~/components/Mobile'
import { MobileOverlay } from './Navigation.style'
import { Unicode } from '~/constants/unicode'
import { isMobile } from 'react-device-detect'
import { navigate } from '@reach/router'
import queryString from 'query-string'
import useCategories from '~/hooks/useCategories'
import { useLocation } from '@reach/router'

// todo: get updated state in listener to prevent handleMenuHover opening the menu again
export default () => {
    const [isHover, setIsHover] = useState(true)
    const timeoutRef = useRef<number>()
    const [isOpen, setIsOpen] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const { categories, columns } = useCategories()
    const {
        search: locationSearch,
        state: locationState,
        pathname: locationPathname
    } = useLocation()
    const { s } = locationSearch ? queryString.parse(locationSearch) : { s: '' }
    const selectedCategories = s ? (s as string).split(',') : []
    const closeDurationMs = 1000
    const fadeDurationMs = 1000

    useEffect(() => {
        window.addEventListener('touchstart', onTouchStart, { passive: true })

        return function dispose() {
            window.removeEventListener('touchstart', onTouchStart)
        }
    }, [])

    useEffect(() => {
        clearTimeout(timeoutRef.current)

        if (!isHover) {
            timeoutRef.current = setTimeout(() => {
                closeMenu()
            }, closeDurationMs)
        }

        return () => clearTimeout(timeoutRef.current)
    }, [isHover, locationPathname, locationSearch])

    useEffect(() => {
        // enable menu immediately when opened
        if (isOpen) {
            setIsDisabled(false)
            return
        }

        // disable menu after timeout when closed
        const disableMenuAfterFadeOut = setTimeout(() => {
            if (!isOpen) {
                setIsDisabled(true)
            }
        }, fadeDurationMs)

        return () => clearTimeout(disableMenuAfterFadeOut)
    }, [isOpen])

    function closeMenu() {
        setIsOpen(false)
    }

    function onTouchStart(event: TouchEvent) {
        if ((event.target as any).nodeName === 'H1') {
            return
        }
        closeMenu()
    }

    function openMenu() {
        setIsOpen(true)
    }

    function handleMenuClick() {
        if (isMainMenu() && !isOpen) {
            openMenu()
        } else if ((locationState as any).prevPath) {
            navigate((locationState as any).prevPath)
        } else {
            navigate('/')
        }
    }

    function handleMenuHover() {
        if (!isMobile && isMainMenu()) {
            openMenu()
        }
    }

    function applyFilters() {
        if (selectedCategories.length) {
            navigate(`projects?s=${selectedCategories.join(',')}`)
        } else {
            navigate('projects')
        }
        if (isMobile) {
            setIsHover(false)
        }
    }

    function setCategoryFilter(category: Category) {
        if (!selectedCategories.includes(category.url.current)) {
            selectedCategories.push(category.url.current)
            applyFilters()
        }
    }

    function unsetCategoryFilter(category: Category) {
        const categoryIndex = selectedCategories.indexOf(category.url.current)

        if (categoryIndex >= 0) {
            selectedCategories.splice(categoryIndex, 1)
            applyFilters()
        }
    }

    function isMainMenu() {
        return ['/', '/projects'].includes(locationPathname)
    }

    return (
        <>
            <Nav
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                isDisabled={isDisabled}
            >
                <Box display="flex">
                    <Box flexGrow={1}>
                        <MenuClosed isVisible={!isOpen} fadeDurationMs={fadeDurationMs}>
                            <MenuButton onClick={handleMenuClick} onMouseEnter={handleMenuHover}>
                                <ColorTypography variant="h1">
                                    <Box paddingRight={2} paddingBottom={1}>
                                        {isMainMenu() ? '+' : Unicode.LEFT}
                                    </Box>
                                </ColorTypography>
                            </MenuButton>
                        </MenuClosed>
                        <MenuOpen isVisible={isOpen} fadeDurationMs={fadeDurationMs}>
                            <Mobile>
                                <MenuButton onClick={closeMenu}>
                                    <ColorTypography variant="h1">
                                        {Unicode.CLOSE}
                                        <br />
                                    </ColorTypography>
                                </MenuButton>
                            </Mobile>
                            <Desktop>
                                <MenuButton>
                                    <ColorTypography variant="h1">
                                        FILTER BY
                                        <br />
                                    </ColorTypography>
                                </MenuButton>
                            </Desktop>
                            {categories.map((category) => (
                                <CategoryFilter
                                    category={category}
                                    isActive={selectedCategories.includes(category.url.current)}
                                    setCategoryFilter={setCategoryFilter}
                                    unsetCategoryFilter={unsetCategoryFilter}
                                    key={category.url.current}
                                />
                            ))}
                            {locationPathname !== '/' && (
                                <Link to="/" onClick={closeMenu}>
                                    <LineBreak variant="h1" lines={2} />
                                    <ColorTypography variant="h1">OVERVIEW</ColorTypography>
                                </Link>
                            )}
                        </MenuOpen>
                    </Box>
                    {locationPathname !== '/about' && (
                        <AboutLink>
                            <Link
                                to="/about"
                                state={{ prevPath: locationPathname + locationSearch }}
                                onClick={closeMenu}
                            >
                                <ColorTypography variant="h1">
                                    <Box paddingLeft={2} paddingBottom={1}>
                                        ?
                                    </Box>
                                </ColorTypography>
                            </Link>
                        </AboutLink>
                    )}
                </Box>
            </Nav>
            <MobileOverlay isVisible={isOpen} fadeDurationMs={fadeDurationMs} />
            <CategoryTitles>
                <ColorTypography variant="h1">
                    {categories
                        .filter((category) => selectedCategories.includes(category.url.current))
                        .map((category) => category.title.toUpperCase())
                        .join(' / ')}
                </ColorTypography>
            </CategoryTitles>
            <ColumnTitles
                isVisible={locationPathname === '/' && !isOpen}
                fadeDurationMs={fadeDurationMs}
            >
                <Grid container>
                    {columns.map((column, index) => (
                        <Grid item md={4} key={column.title}>
                            <ColumnContainer>
                                <ColumnTitle
                                    align={index === 0 ? 'top' : index === 1 ? 'middle' : 'bottom'}
                                >
                                    <ColorTypography variant="h1">
                                        {column.title.toUpperCase()}
                                    </ColorTypography>
                                </ColumnTitle>
                            </ColumnContainer>
                        </Grid>
                    ))}
                </Grid>
            </ColumnTitles>
        </>
    )
}
