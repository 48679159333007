import ColorContext from '~/contexts/colorContext'
import { useContext } from 'react'

export default () => {
    const context = useContext(ColorContext)

    if (context === undefined) {
        throw new Error('useColorContext must be used within a ColorProvider')
    }

    return context
}
