import React, { useState } from 'react'

import { Box } from '@material-ui/core'
import { Category } from '~/types'
import ColorTypography from '~/components/ColorTypography'
import TypographyButton from '~/components/TypographyButton'
import { VideoContainer } from './CategoryFilter.style'

type ViewProps = {
    category: Category
    isActive: boolean
    setCategoryFilter(category: Category): void
    unsetCategoryFilter(category: Category): void
}

export default ({ category, isActive, setCategoryFilter, unsetCategoryFilter }: ViewProps) => {
    const [isHover, setIsHover] = useState(false)
    const { title, hoverProject } = category

    return (
        <>
            <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                {(isActive || isHover) && (
                    <Box minWidth="20px" display="inline-block">
                        <ColorTypography variant="h1" align="center">
                            {isActive ? '-' : isHover ? '+' : ''}
                        </ColorTypography>
                    </Box>
                )}

                <TypographyButton
                    title={title.toUpperCase()}
                    onClick={() =>
                        isActive ? unsetCategoryFilter(category) : setCategoryFilter(category)
                    }
                />
            </div>
            {isHover && (
                <VideoContainer>
                    <video muted autoPlay loop playsInline>
                        <source src={hoverProject.teaserVideoFile.asset.url} type="video/mp4" />
                    </video>
                </VideoContainer>
            )}
        </>
    )
}
