import './fonts/fontFace.css'

import { createMuiTheme } from '@material-ui/core/styles'
import palette from './palette'
import { responsiveFont } from '~/utils/font'

export default () =>
    createMuiTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1800,
                xl: 1920
            }
        },
        typography: {
            fontFamily: 'GTAmericaRegular',
            allVariants: {
                wordWrap: 'break-word',
                lineHeight: 1.3
            },
            h3: responsiveFont(18, 23, 18, 20, 21),
            h1: responsiveFont(24, 29, 24, 26, 27)
        },
        spacing: (factor) => `${0.25 * factor}rem`,
        palette: {
            type: 'light',
            ...palette
        },
        overrides: {
            MuiDialog: {
                container: {
                    backgroundColor: 'white'
                },
                paper: {
                    boxShadow: 'none',
                    margin: 0
                }
            }
        }
    })
