import { ButtonContainer } from './TypographyButton.style'
import ColorTypography from '~/components/ColorTypography'
import React from 'react'

type ViewProps = {
    title: string
    onClick(): void
}

export default ({ title, onClick }: ViewProps) => {
    return (
        <ButtonContainer>
            <ColorTypography variant="h1" onClick={onClick}>
                {title}
            </ColorTypography>
        </ButtonContainer>
    )
}
