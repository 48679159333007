import React, { ReactNode, useMemo, useState } from 'react'

import TooltipContext from '~/contexts/tooltipContext'

type ViewProps = {
    children: ReactNode
}

export default ({ children }: ViewProps) => {
    const [tooltip, setTooltip] = useState('')

    return (
        <TooltipContext.Provider
            value={useMemo(
                () => ({
                    tooltip,
                    setTooltip
                }),
                [tooltip, setTooltip]
            )}
        >
            {children}
        </TooltipContext.Provider>
    )
}
