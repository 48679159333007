import { Theme } from '@material-ui/core'
import { contentMargin } from '~/styles/sizes'
import styled from 'styled-components'

export const Nav = styled.nav<{ isDisabled: boolean }>`
    position: fixed;
    z-index: 5000;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
    h1 {
        display: inline-block;
    }
    padding: ${({ theme }) => theme.spacing(contentMargin)};
`

type MenuProps = {
    isVisible: boolean
    fadeDurationMs: number
}

const Menu = styled.div<MenuProps>`
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition-property: opacity;
    pointer-events: none;

    h1 {
        pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
    }
`

export const MenuClosed = styled(Menu)`
    transition-duration: ${({ isVisible, fadeDurationMs }) => (isVisible ? fadeDurationMs : 0)}ms;

    h1 {
        cursor: ${({ isVisible }) => (isVisible ? 'pointer' : 'auto')};
    }
`

export const MenuOpen = styled(Menu)`
    transition-duration: ${({ isVisible, fadeDurationMs }) => (isVisible ? 0 : fadeDurationMs)}ms;
    position: absolute;
    top: 0;
    left: 0;
    padding: ${({ theme }) => theme.spacing(contentMargin)};
`

export const MenuButton = styled.div`
    ${({ theme }) => `
        transform: translate(${theme.spacing(-contentMargin)}, ${theme.spacing(-contentMargin)});

        h1 {
            padding: ${theme.spacing(contentMargin)};    

            ${theme.breakpoints.up('md')} {    
                padding-right: ${theme.spacing(0)};
                padding-bottom: ${theme.spacing(0)};
            }

            margin-bottom: 20px;
        }
    `}
`

export const MobileOverlay = styled(Menu)`
    transition-duration: ${({ isVisible, fadeDurationMs }) => (isVisible ? 0 : fadeDurationMs)}ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background-color: black;
    ${({ theme }) => `            
        ${theme.breakpoints.up('md')} {    
            display: none;
        }
    `}
`

export const AboutLink = styled.div`
    ${({ theme }) => `
        transform: translate(${theme.spacing(contentMargin)}, ${theme.spacing(-contentMargin)});

        h1 {
            padding: ${theme.spacing(contentMargin)};    

            ${theme.breakpoints.up('md')} {    
                padding-left: ${theme.spacing(0)};
                padding-bottom: ${theme.spacing(0)};
            }
        }
    `}

    a {
        pointer-events: all;
    }
`

export const CategoryTitles = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: none;
    padding: ${({ theme }) => theme.spacing(contentMargin)};
    ${({ theme }) => `    
        ${theme.breakpoints.up('md')} {
            display: block;
        }
    `}
`

export const ColumnTitles = styled.div<{ isVisible: boolean; fadeDurationMs: number }>`
    position: absolute;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
    z-index: 1000;
    pointer-events: none;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition-property: opacity;
    transition-duration: ${({ isVisible, fadeDurationMs }) => (isVisible ? fadeDurationMs : 0)}ms;
    display: none;
    ${({ theme }) => `    
        ${theme.breakpoints.up('md')} {
            display: block;
        }
    `}
`

export const ColumnContainer = styled.div`
    position: relative;
    height: 100vh;
`

type ColumnTitleProps = {
    theme: Theme
    align: 'top' | 'middle' | 'bottom'
}

export const ColumnTitle = styled.div<ColumnTitleProps>`
    position: absolute;
    top: ${({ align }) => (align === 'bottom' ? 'auto' : 0)};
    bottom: ${({ align }) => (align === 'bottom' ? 0 : 'auto')};
    display: flex;
    align-items: center;
    width: 100%;
    height: ${({ align }) => (align === 'middle' ? 100 : 50)}vh;
    pointer-events: none;
    justify-content: center;
`
