import 'intersection-observer'

import { AppContainer, GlobalStyle } from './Layout.style'
import React, { ReactNode } from 'react'

import ColorProvider from '~/components/ColorProvider'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import Navigation from '~/components/Navigation'
import { ThemeProvider as ScThemeProvider } from 'styled-components'
import TooltipProvider from '~/components/TooltipProvider'
import useTheme from '~/hooks/useTheme'

type ViewProps = {
    children: ReactNode
}

export default ({ children }: ViewProps) => {
    const theme = useTheme()

    return (
        <MuiThemeProvider theme={theme}>
            <ScThemeProvider theme={theme}>
                <ColorProvider>
                    <TooltipProvider>
                        <CssBaseline />
                        <GlobalStyle />
                        <AppContainer>
                            <Navigation />
                            <main>{children}</main>
                        </AppContainer>
                    </TooltipProvider>
                </ColorProvider>
            </ScThemeProvider>
        </MuiThemeProvider>
    )
}
