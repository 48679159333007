import React from 'react'
import { Typography } from '@material-ui/core'

type ViewProps = {
    lines: number
    variant?:
        | 'button'
        | 'caption'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'inherit'
        | 'subtitle1'
        | 'subtitle2'
        | 'body1'
        | 'body2'
        | 'overline'
        | 'srOnly'
        | undefined
}

export default ({ lines, variant }: ViewProps) => (
    <Typography variant={variant}>
        {Array.from(Array(lines)).map((x, index) => (
            <br key={index} />
        ))}
    </Typography>
)
