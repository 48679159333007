import React, { ReactNode, useMemo, useState } from 'react'

import ColorContext from '~/contexts/colorContext'
import interpolate from 'color-interpolate'
import useAnimationFrame from '~/hooks/useAnimationFrame'

type ViewProps = {
    children: ReactNode
}

export default ({ children }: ViewProps) => {
    const period = 50000
    const [color, setColor] = useState('cyan')
    const colorMap = interpolate(['cyan', 'magenta', 'yellow', 'cyan'])

    useAnimationFrame((dt) => {
        const progress = (Date.now() % period) / period

        setColor(colorMap(progress))
    }, true)

    return (
        <ColorContext.Provider
            value={useMemo(
                () => ({
                    color
                }),
                [color]
            )}
        >
            {children}
        </ColorContext.Provider>
    )
}
