import { Category, OverviewColumn } from '~/types'

import { graphql } from 'gatsby'
import { useStaticQuery } from 'gatsby'

export default () => {
    const { allSanityCategory, sanityOverview } = useStaticQuery(graphql`
        query Categories {
            allSanityCategory {
                nodes {
                    title
                    url {
                        current
                    }
                    hoverProject {
                        teaserVideoFile {
                            asset {
                                url
                            }
                        }
                    }
                }
            }
            sanityOverview {
                columns {
                    title
                }
            }
        }
    `)

    return {
        categories: allSanityCategory.nodes as Category[],
        columns: sanityOverview.columns as OverviewColumn[]
    }
}
