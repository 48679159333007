import { Box, Typography } from '@material-ui/core'
import React, { ReactNode } from 'react'

import useColorContext from '~/hooks/useColorContext'

type ViewProps = {
    children: ReactNode
    onClick?: () => void
    variant?:
        | 'button'
        | 'caption'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'inherit'
        | 'subtitle1'
        | 'subtitle2'
        | 'body1'
        | 'body2'
        | 'overline'
        | 'srOnly'
        | undefined
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined
}

export default ({ children, onClick, variant, align }: ViewProps) => {
    const { color } = useColorContext()

    return (
        <Box color={color} display={align === 'center' ? 'block' : 'inline-block'}>
            <Typography onClick={onClick} variant={variant} align={align}>
                {children}
            </Typography>
        </Box>
    )
}
