import styled from 'styled-components'

export const VideoContainer = styled.div`
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: none;
    ${({ theme }) => `    
        ${theme.breakpoints.up('md')} {
            display: block;
        }
    `}

    video {
        width: 100vw;
        margin-left: 50%;
        transform: translateX(-50%);
        mask-image: linear-gradient(rgba(0, 0, 0, 1) 0%, transparent 90%);
    }
`
